import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintService } from '../../services/remittance/print-receipt.service';
import { RemittanceService } from '../../services/remittance/remittance.service';
import { RemittanceResponseDto } from '../../shared/dto/admin/remittance.dto';
import { DateFormatService } from '../../shared/data/date-format.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css'],
})
export class ReceiptComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  remittanceDetail: RemittanceResponseDto;

  constructor(
    route: ActivatedRoute,
    private printService: PrintService,
    private readonly remittanceService: RemittanceService,
    public dateFormatService: DateFormatService,
  ) {}

  ngOnInit() {
    this.remittanceDetail = this.remittanceService.remittanceDetail;
    this.printService.onDataReady();
  }
}
