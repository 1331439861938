import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonPaginateResponse, CommonResponse } from '../api-response.dto';

@Injectable({
  providedIn: 'root',
})
export class ProfileServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getAccountInfo() {
    return this.http.get<CommonResponse>( `${environment.apiUrl}/user/account-info`, {} );
  }
}
