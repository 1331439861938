import { Router } from '@angular/router';
import { RouteHelper } from './../../helpers/route.helpers';
import { Component, DoCheck, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AuthenticationServices } from '../../services/authentication.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { CustomerProfileServices } from '../../services/customer/profile.service';
import { first } from 'rxjs/operators';
import { dataEnum } from 'src/app/helpers/data.enum';
import { EncryptDataService } from 'src/app/helpers/encrypt.service';
import { StorageDataService } from 'src/app/helpers/storageData.service';
import { EkycCheckDto } from 'src/app/shared/dto/customer/profile.dto';
import { CustomerProfileDto } from '../../shared/dto/customer/profile.dto';
import { ProfileDataDto } from '../../shared/dto/admin/profile.dto';

@Component({
  selector: 'app-mainpage-layout',
  templateUrl: './mainpage-layout.component.html',
  styleUrls: ['./mainpage-layout.component.scss'],
})
export class MainPageLayoutComponent implements OnInit, DoCheck {
  constructor(
    public routeHelper: RouteHelper,
    public authenticationServices: AuthenticationServices,
    private storageDataService: StorageDataService,
    public sidebarService: SidebarService
  ) {}

  notHome;
  logged;
  dateNow;
  toggleMemberMenu;

  dataAuth;
  dataProfile: ProfileDataDto;

  async ngOnInit(): Promise<void> {
    this.dateNow = moment().format('YYYY');
    this.toggleMemberMenu = false;
  }

  ngDoCheck() {
    this.dataAuth = this.storageDataService.getAuthDataStorage();
    this.dataProfile = this.storageDataService.getProfileStorage();
    const currentTime = moment();
    if (this.dataAuth && this.dataProfile && currentTime.isBefore(moment(this.dataAuth.expires))) {
      this.logged = true;
    }
  }

  toggleMenu() {
    this.toggleMemberMenu = !this.toggleMemberMenu;
  }

  goToPage(){
    if (this.dataProfile.userType === 'ADMIN') {
      this.routeHelper.navigated('dashboard');
    } else {
      this.routeHelper.navigated('member/account');
    }
  }
}
