import { AuthMemberPayloadDto } from './../../../shared/dto/auth.dto';
import { AuthBloc } from './../../../shared/bloc/auth/auth.bloc';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { EncryptDataService } from '../../../helpers/encrypt.service';
import { AuthAgentPayloadDto } from '../../../shared/dto/auth.dto';
import { dataEnum } from '../../../helpers/data.enum';
import { ProfileDataDto } from '../../../shared/dto/admin/profile.dto';
import { EkycCheckDto } from '../../../shared/dto/customer/profile.dto';
import { RouteHelper } from 'src/app/helpers/route.helpers';

@Component({
  selector: 'app-login-agent',
  templateUrl: './login-agent.component.html',
  styleUrls: ['./login-agent.component.css'],
})
export class LoginAgentComponent implements OnInit {
  constructor(
    private router: Router,
    private encryptDataService: EncryptDataService,
    private authBloc: AuthBloc,
    private formBuilder: FormBuilder,
    public routeHelper: RouteHelper
  ) {}

  loginAgentFg = new FormGroup({
    branchCode: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    recaptcha: new FormControl('', Validators.required)
  });


  isCustomer: boolean;

  errResponse: string;
  preloader: boolean;
  returnFormValidation: AuthAgentPayloadDto;
  dataLogin: AuthAgentPayloadDto;

  fieldTextType: boolean;
  siteKey :string
  size :any
  theme :any
  type :any

  ngOnInit() {
    this.size = "normal";
    this.theme = "light";
    this.type = "image"
    this.siteKey = "6LcNTUcgAAAAAMP7brHhKd9zx72oV4iNYJ98flGD";

    // remove user from local storage and set current user to null
    localStorage.removeItem(dataEnum.auth);
    localStorage.removeItem(dataEnum.profile);
    localStorage.removeItem(dataEnum.ekyc);
    localStorage.removeItem(dataEnum.menu);

    this.preloader = false;
    window.scrollTo(0, 0);
  }

  closeAlert() {
    this.errResponse = null;
  }

  navigated(url: string) {
    this.router.navigate([url]);
  }

  get validatingLoginAgent() {
    return this.loginAgentFg.controls;
  }

  async onSubmitAgent() {
    this.dataLogin = this.loginAgentFg.value;
    if (this.loginAgentFg.valid) {
      if (this.dataLogin.branchCode === 'SUPERADMIN') {
        this.dataLogin.branchCode = null;
      }
      this.setLoginAgent();
    }else{
      if(this.dataLogin.recaptcha === ''){
      this.preloader = false;
        this.errResponse = 'Captcha Invalid';
      }else{
        this.preloader = false;
        this.errResponse = 'Username / Password Wrong!';
      }
    }
    
  }

  async setLoginAgent() {
    if (this.dataLogin) {
      this.preloader = true;
      const responseLogin = await this.authBloc.authAgent(this.dataLogin);
      if (responseLogin) {

        // set local storage for next call api
        const hashPreloginData = JSON.stringify(responseLogin.data);
        localStorage.setItem(dataEnum.auth, this.encryptDataService.encryptData(hashPreloginData));

        const resultProfileData = await this.authBloc.getAccountInfo();
        if (!resultProfileData) {
          this.preloader = false;
          this.errResponse = 'Ops! Username / Password Wrong!';
        }

        let isCustomer = false;
        resultProfileData.userType = dataEnum.admin;
        if (resultProfileData.roles[0].name === 'customer') {
          isCustomer = true;
          resultProfileData.userType = dataEnum.customer;

          const ekycData = await this.authBloc.getEkyc();
          if (!ekycData) {
            this.preloader = false;
            this.errResponse = 'Ops! Something when wrong to verify your profile';
          }

          const ekycCheck: EkycCheckDto = ekycData.data;
          const hash = JSON.stringify(ekycCheck);
          localStorage.setItem(dataEnum.ekyc, this.encryptDataService.encryptData(hash));
        }

        if (isCustomer) {
          this.setLocalStorage(null, resultProfileData);
          this.preloader = false;
          this.router.navigate(['member/dashboard']);
        } else {
          const resultMenuData = await this.authBloc.getMenu();
          if (resultMenuData && resultProfileData) {
            this.setLocalStorage(resultMenuData.data, resultProfileData);
            this.preloader = false;
            this.router.navigate(['dashboard']);
          } else {
            this.preloader = false;
            this.errResponse = 'Ops! Something when wrong to get menu data';
          }
        }
      } else {
        this.preloader = false;
      }
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  async setLocalStorage(
    resultMenuData: any,
    resultProfileData: ProfileDataDto
  ) {
    if (resultMenuData) {
      const hashMenuData = JSON.stringify(resultMenuData);
      localStorage.setItem(
        dataEnum.menu,
        this.encryptDataService.encryptData(hashMenuData)
      );
    }

    if (resultProfileData) {
      const hashProfile = JSON.stringify(resultProfileData);
      localStorage.setItem(
        dataEnum.profile,
        this.encryptDataService.encryptData(hashProfile)
      );
    }
  }
}
