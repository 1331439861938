import { AddBeneficieryDto } from './../../dto/customer/beneficieries';
import { CustomerProfileServices } from 'src/app/services/customer/profile.service';
import { DataCustomerService } from './../../../services/administation/data-customer.service';
import {
  TransformApiResponseDto,
  CommonResponse,
} from './../../../services/api-response.dto';
import {
  InquiryBankTransferDto,
  InquiryPaymentDigital,
  RemittancePayloadDto,
} from './../../dto/admin/remittance.dto';
import { RemittanceService } from './../../../services/remittance/remittance.service';
import { Injectable } from '@angular/core';
import { CustomerBeneficieriesService } from '../../../services/customer/beneficieries';
import { RateManagementServices } from '../../../services/administation/rate-management.service';
import { CustomerBranchService } from '../../../services/customer/branch.service';
import { ServiceChargeService } from '../../../services/administation/service-charge.service';
import { MasterDataProviderServices } from '../../../services/masterdata/masterdata-provider.service';
import { MasterDataBankServices } from '../../../services/masterdata/masterdata-bank.service';
import { MasterDataProductServices } from '../../../services/masterdata/masterdata-product.service';
import { BranchManagementServices } from './../../../services/branch/branch-management.service';
import {
  CustomerConfirmationTransferPayloadDto,
  RemittancePrePaidDto,
} from '../../dto/customer/transaction.dto';
import { UpdateProfileDto } from '../../dto/customer/profile.dto';
import { InquiryAddBeneficieryDto } from '../../dto/customer/beneficieries';
@Injectable({
  providedIn: 'root',
})
export class CustomerBloc {
  constructor(
    private customerBeneficieriesService: CustomerBeneficieriesService,
    private readonly rateManagementServices: RateManagementServices,
    private readonly customerBranchService: CustomerBranchService,
    private readonly serviceChargeService: ServiceChargeService,
    private readonly masterDataProviderServices: MasterDataProviderServices,
    private readonly masterDataBankServices: MasterDataBankServices,
    private readonly masterDataProductServices: MasterDataProductServices,
    private readonly remittanceService: RemittanceService,
    private readonly dataCustomerService: DataCustomerService,
    private readonly branchManagementServices: BranchManagementServices,
    private customerProfileServices: CustomerProfileServices
  ) {}

  async getIdentityImage(): Promise<any> {
    return new Promise(async (resolve) => {
      this.dataCustomerService.getHasIdentityImage().subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getIdentitySelfieImage(): Promise<any> {
    return new Promise(async (resolve) => {
      this.dataCustomerService.getHasIdentitySelfieImage().subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getIdentitySelfieBackImage(): Promise<any> {
    return new Promise(async (resolve) => {
      this.dataCustomerService.getHasIdentitySelfieBackImage().subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async updateCustomerProfile(dataProfile: UpdateProfileDto): Promise<any> {
    return new Promise(async (resolve) => {
      this.customerProfileServices.submitProfile(dataProfile).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async getInternalBranch(): Promise<any> {
    return new Promise(async (resolve) => {
      this.customerBranchService.getInternalBranch().subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerBeneficiery(): Promise<any> {
    return new Promise(async (resolve) => {
      this.customerBeneficieriesService.getBeneficieries().subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getBaseCurrency(): Promise<any> {
    return new Promise(async (resolve) => {
      this.rateManagementServices.getBaseCurrenciesSelect(null).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerRate(param): Promise<any> {
    return new Promise(async (resolve) => {
      this.rateManagementServices.getRatesCustomer(param).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getServiceCharge(param): Promise<any> {
    return new Promise(async (resolve) => {
      this.serviceChargeService.getCustomerServiceCharge(param).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerMainProvider(): Promise<any> {
    return new Promise(async (resolve) => {
      this.masterDataProviderServices.getCustomerMainProvider(null).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerBank(): Promise<any> {
    return new Promise(async (resolve) => {
      this.masterDataBankServices.getBankSelect(null, 'customer').subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getPaydiaBank(): Promise<any> {
    return new Promise(async (resolve) => {
      this.masterDataBankServices.getBankPaydiaSelect(null).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getProductCategory(): Promise<any> {
    return new Promise(async (resolve) => {
      this.masterDataProductServices.getProductCategory(null).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerProduct(param): Promise<any> {
    return new Promise(async (resolve) => {
      this.masterDataProductServices.getCustomerProduct(param).subscribe(
        (data) => {
          resolve(data.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async submitInquiryPaymentDigital(
    payload: InquiryPaymentDigital
  ): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.remittanceService.addInquiryPaymentDigital(payload).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async submitRemittance(
    payload: RemittancePayloadDto
  ): Promise<TransformApiResponseDto> {
    return new Promise(async (resolve) => {
      this.remittanceService.addRemittance(payload, true).subscribe(
        async (result) => {
          const returnData = {
            data: result.data,
            message: result.message,
          };
          resolve(returnData);
        },
        (error) => {
          const returnData = {
            data: null,
            message: error.message,
          };
          resolve(returnData);
        }
      );
    });
  }

  async submitPaymentDigital(
    payload: RemittancePrePaidDto
  ): Promise<TransformApiResponseDto> {
    return new Promise(async (resolve) => {
      this.remittanceService.addRemittanceBpjs(payload, true).subscribe(
        async (result) => {
          const returnData = {
            data: result.data,
            message: result.message,
          };
          resolve(returnData);
        },
        (error) => {
          const returnData = {
            data: null,
            message: error.message,
          };
          resolve(returnData);
        }
      );
    });
  }

  getTransactQRDetail(remittanceUuid: string): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService
        .getRemmitanceCustomerDetail(remittanceUuid)
        .subscribe(
          async (result) => {
            resolve(result);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  getTransactionDetail(remittanceUuid: string): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.getRemmitanceDetail(remittanceUuid).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  getTransactionRemittanceHistory(param: any): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.getRemmitanceListPagination(param).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  getRemittancePendingTransaction(param: any): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.getRemittancePendingTransaction(param).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  getBranchDetail(uuid: any): Promise<any> {
    return new Promise(async (resolve) => {
      this.branchManagementServices.getDetailBranch(uuid).subscribe(
        async (result) => {
          resolve(result?.data);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async submitInquiryAddBeneficiery(
    payload: InquiryAddBeneficieryDto
  ): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.addBeneficieryInquiry(payload).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async submitAddBeneficiery(payload: AddBeneficieryDto): Promise<any> {
    return new Promise(async (resolve) => {
      this.customerBeneficieriesService.addBeneficieries(payload).subscribe(
        async (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getCustomerTransferDetail(param: any): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.remittanceService.checkConfirmationTransfer(param).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async submitEditBeneficiery(
    payload: AddBeneficieryDto,
    uuid: string
  ): Promise<any> {
    return new Promise(async (resolve) => {
      this.customerBeneficieriesService
        .updateBeneficieries(payload, uuid)
        .subscribe(
          async (result) => {
            resolve(result);
          },
          (error) => {
            resolve(error);
          }
        );
    });
  }

  async submitConfirmationTransfer(
    dataProfile: CustomerConfirmationTransferPayloadDto
  ): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.submitConfirmTransfer(dataProfile).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getBranchrekening(param: any): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.branchManagementServices.getBranchrekening(param).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getImageConfirmationTransfer(uuid: string): Promise<any> {
    return new Promise(async (resolve) => {
      this.remittanceService.getImageConfirmationTransfer(uuid).subscribe(
        (result) => {
          resolve(result.data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }
}
