<table style="width: 100%; font-size: 9px !important;">
  <thead>
    <tr>
      <th class="text-center">Transaction Number</th>
      <th class="text-center">Date</th>
      <th class="text-center">Sender</th>
      <th class="text-center">Sender Address</th>
      <th class="text-center">Sender IdCard</th>
      <th class="text-center">Beneficiary Name</th>
      <th class="text-center">Beneficiary Account</th>
      <th class="text-center">Bank Name</th>
      <th class="text-center">Bank Unit</th>
      <th class="text-center">Currency From</th>
      <th class="text-center">Currency To</th>
      <th class="text-center">Rate</th>
      <th class="text-center">Original Amout</th>
      <th class="text-center">Total Amout</th>
      <th class="text-center">Scharge</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of financeSupervisorReportData">
      <td>{{ data.transactionNumber }}</td>
      <td>{{ dateFormatService.longFormatDateWithTime(data.created_at) }}</td>
      <td>{{ data.sender }}</td>
      <td>{{ data.senderAddress }}</td>
      <td>{{ data.senderIdCard }}</td>
      <td>{{ data.beneficiaryName }}</td>
      <td>{{ data.accNo }}</td>
      <td>{{ data.bankName }}</td>
      <td>{{ data.bankUnit }}</td>
      <td>{{ data.currencyFrom }}</td>
      <td>{{ data.currencyTo }}</td>
      <td>{{ data.rate }}</td>
      <td>{{ data.originalAmout }}</td>
      <td>{{ data.totalAmount }}</td>
      <td>{{ data.scharge }}</td>
    </tr>
  </tbody>
</table>
