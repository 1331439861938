<div id="preloader2" *ngIf="preloader">
  <div id="status">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>

<section class="section mt-60" *ngIf="!thanksPage" style="background-color: #FFFFFF;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center pb-2">
          <h4 class="title mb-4">Register Agent</h4>
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errRegister">
            <strong>Ops!</strong> {{ messageResponse ? messageResponse : 'Something wrong' }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col mt-4 pt-2" id="forms">
      <div class="component-wrapper rounded shadow">
        <form [formGroup]="registerAgentForm" (ngSubmit)="onSubmit()">
          <div class="p-4 border-bottom">
            <h5 class="title mb-0"> <i data-feather="user" class="fea icon-sm icons"></i> Company Information </h5>
          </div>
          <div class="p-4">
            <div class="row">
              <div class="col-md-8">
                <div class="mb-3">
                  <label class="form-label">Business Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-id-card fea icon-sm icons"></i>
                    <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Business Name"
                      formControlName="bussinessName">
                  </div>
                  <small class="text-danger"
                    *ngIf="validating.bussinessName.invalid && validating.bussinessName.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.bussinessName }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Business Phone <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-phone fea icon-sm icons"></i>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="Business Phone"
                      formControlName="bussinessPhone">
                  </div>
                  <small class="text-danger"
                    *ngIf="validating.bussinessPhone.invalid && validating.bussinessPhone.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.bussinessPhone }}</small>
                </div>
              </div>

              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Country <span class="text-danger">*</span></label>
                  <ngx-select formControlName="country"
                    [items]="countriesData"
                    (selectionChanges)="changeCountry($event)"
                    [showOptionNotFoundForEmptyItems]='true'
                    optionValueField="optionId"
                    optionTextField="optionTitle"
                    placeholder="Select Country"> </ngx-select>
                  <small class="text-danger" *ngIf="validating.country.invalid && validating.country.touched">This field
                    is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.country }}</small>
                  <small class="text-danger" *ngIf="errResponse">{{ messageResponse }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Province <span class="text-danger">*</span></label>
                  <ngx-select formControlName="province"
                    [items]="provinceData"
                    (selectionChanges)="changeProvince($event)"
                    [showOptionNotFoundForEmptyItems]='true'
                    optionValueField="optionId"
                    optionTextField="optionTitle"
                    placeholder="Select Province"> </ngx-select>
                  <small class="text-danger" *ngIf="validating.province.invalid && validating.province.touched">This
                    field is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.province }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">City <span class="text-danger">*</span></label>
                  <ngx-select formControlName="city"
                    [items]="cityData"
                    [showOptionNotFoundForEmptyItems]='true'
                    optionValueField="optionId"
                    optionTextField="optionTitle"
                    placeholder="Select City"> </ngx-select>
                  <small class="text-danger" *ngIf="validating.city.invalid && validating.city.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.city }}</small>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Business Address <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-map-marker fea icon-sm icons"></i>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="Business Address"
                      formControlName="address">
                  </div>
                  <small class="text-danger" *ngIf="validating.address.invalid && validating.address.touched">This field
                    is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.address }}</small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Postal Code</label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-envelope-open fea icon-sm icons"></i>
                    <input type="number" name="subject" id="subject" class="form-control ps-5" placeholder="Postal Code"
                      formControlName="postalCode">
                  </div>
                  <small class="text-danger" *ngIf="validating.postalCode.invalid && validating.postalCode.touched">This
                    field is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.postalCode }}</small>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label class="form-label">Base Currency <span class="text-danger">*</span></label>
                <ngx-select formControlName="basedCurrency"
                [allowClear]="true"
                [items]="currenciesData"
                optionValueField="optionId"
                optionTextField="optionTitle"
                placeholder="Select Base Currency"> </ngx-select>
                <small class="text-danger" *ngIf="validating.basedCurrency.invalid && validating.basedCurrency.touched">This field
                  is required</small>
                <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.basedCurrency }}</small>
                <small class="text-danger" *ngIf="errResponse">{{ messageResponse }}</small>
              </div>
            </div>
          </div>
          <div class="p-4 border-bottom">
            <h5 class="title mb-0"> <i data-feather="settings" class="fea icon-sm icons"></i> User Data </h5>
          </div>
          <div class="p-4">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Username <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-user fea icon-sm icons"></i>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="Account Name"
                      formControlName="accountName">
                  </div>
                  <small class="text-danger">*</small> <span style="font-size: 12px;">Please remember this username for login account</span> <br>
                  <small class="text-danger"
                    *ngIf="validating.accountName.invalid && validating.accountName.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.accountName }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-envelope-o fea icon-sm icons"></i>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="Email"
                      formControlName="accountEmail">
                  </div>
                  <small class="text-danger"
                    *ngIf="validating.accountEmail.invalid && validating.accountEmail.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.accountEmail }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Account Phone <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-phone fea icon-sm icons"></i>
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="Account Phone"
                      formControlName="accountPhone">
                  </div>
                  <small class="text-danger"
                    *ngIf="validating.accountPhone.invalid && validating.accountPhone.touched">This field is
                    required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.accountPhone }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Password <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-key fea icon-sm icons"></i>
                    <input name="subject" type="password" id="subject" class="form-control ps-5" placeholder="Password"
                      formControlName="password">
                  </div>
                  <small class="text-danger" *ngIf="validating.password.invalid && validating.password.touched">This
                    field is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.password }}</small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i class="fa fa-key fea icon-sm icons"></i>
                    <input name="subject" type="password" id="subject" class="form-control ps-5"
                      placeholder="Confirm Password" formControlName="passwordConfirmation">
                  </div>
                  <small class="text-danger"
                    *ngIf="validating.passwordConfirmation.invalid && validating.passwordConfirmation.touched">This
                    field is required</small>
                  <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.passwordConfirmation }}</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                      formControlName="confirmRegister">
                    <label class="form-check-label" for="flexSwitchCheckDefault">
                      By registering, I accept the <a href="javascript:void(0)" (click)="navigated('privacy-policy')">Terms of Service</a> and <a href="javascript:void(0);" (click)="navigated('privacy-policyx')">Privacy Policy</a>.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <ngx-recaptcha2 #captchaElem
                      [siteKey]="siteKey"
                      [useGlobalDomain]="true"
                      [size]="size"
                      [theme]="theme"
                      [type]="type"
                      formControlName="recaptcha">
                    </ngx-recaptcha2>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <button type="submit" id="submit" name="send" class="btn btn-primary"
                    [disabled]="preloader">Register</button>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <p>&nbsp;</p>
                  Already register ? <a href="javascript:void(0)" (click)="navigated('login')">Login here</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="bg-home bg-light d-flex align-items-center" *ngIf="thanksPage">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img src="assets/images/illustrator/envelope.svg" class="img-fluid mx-auto d-block" alt="">
      </div>

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Thank you <br> for register your account.</h4>
          <p class="text-muted para-desc mb-0">Please check your email to verify your account.</p>
          <div class="my-4">
            <a (click)="navigated('/')" class="btn btn-outline-primary m-1"><i class="uil uil-home"></i> Back to
              Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
