import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';

@Injectable({
  providedIn: 'root',
})
export class MasterDataProductServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getProduct(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/product', {
      params: param,
    });
  }

  getProductCategory(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/productCategory', {
      params: param,
    });
  }

  getCustomerProduct(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/customer/product', {
      params: param,
    });
  }
}
