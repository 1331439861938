import { Injectable } from '@angular/core';
import { EncryptDataService } from './encrypt.service';
import { dataEnum } from './data.enum';
import { ProfileDataDto } from '../shared/dto/admin/profile.dto';

@Injectable({
  providedIn: 'root',
})
export class StorageDataService {
  constructor(private encryptDataService: EncryptDataService) {}

  getAuthDataStorage() {
    try {
      const storageData = localStorage.getItem(dataEnum.auth);
      if (storageData) {
        return JSON.parse(
          this.encryptDataService.decryptData(storageData)
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  getProfileStorage() {
    try {
      const storageData = localStorage.getItem(dataEnum.profile);
      if (storageData) {
        return JSON.parse(
          this.encryptDataService.decryptData(storageData)
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  getEkycStorage() {
    try {
      const storageData = localStorage.getItem(dataEnum.ekyc);
      if (storageData) {
        return JSON.parse(
          this.encryptDataService.decryptData(storageData)
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  getMenuStorage() {
    try {
      const storageData = localStorage.getItem(dataEnum.menu);
      if (storageData) {
        return JSON.parse(
          this.encryptDataService.decryptData(storageData)
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}
