import { CommonResponse } from './../api-response.dto';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonPaginateResponse } from '../api-response.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyReportServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getDailyReport(param: any) {
    return this.http.get<CommonPaginateResponse>(
      `${environment.apiUrl}/report/dailyoperator`,
      {
        params: param,
      }
    );
  }

  getMonthlyReport(param: any) {
    return this.http.get<CommonPaginateResponse>(
      `${environment.apiUrl}/report/monthlyreport`,
      {
        params: param,
      }
    );
  }

  getFinanceSupervisorReport(param: any) {
    return this.http.get<CommonPaginateResponse>(
      `${environment.apiUrl}/report/financesupervior`,
      {
        params: param,
      }
    );
  }

  getFinanceSupervisorReportNoPagination(param: any) {
    return this.http.get<CommonResponse>(
      `${environment.apiUrl}/report/financesupervior`,
      {
        params: param,
      }
    );
  }

  downloadDailyReportOperator<T>(param: any): Observable<T> {
    const headersJSON: HttpHeaders = new HttpHeaders({
      timeout: `${300 * 60 * 1000}`,
    }).append('Content-Type', 'application/json');
    return this.http.post<T>(
      environment.apiUrl + '/report/dailyoperator-download',
      param,
      {
        headers: headersJSON,
        responseType: 'blob' as 'json',
      }
    );
  }

  downloadMonthlyReportOperator<T>(param: any): Observable<T> {
    const headersJSON: HttpHeaders = new HttpHeaders({
      timeout: `${300 * 60 * 1000}`,
    }).append('Content-Type', 'application/json');
    return this.http.post<T>(
      environment.apiUrl + '/report/monthlyreport-download',
      param,
      {
        headers: headersJSON,
        responseType: 'blob' as 'json',
      }
    );
  }

  downloadFinanceSupervisorReport<T>(param: any): Observable<T> {
    const headersJSON: HttpHeaders = new HttpHeaders({
      timeout: `${300 * 60 * 1000}`,
    }).append('Content-Type', 'application/json');
    return this.http.post<T>(
      environment.apiUrl + '/report/financesupervior-download',
      param,
      {
        headers: headersJSON,
        responseType: 'blob' as 'json',
      }
    );
  }
}
