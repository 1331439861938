<section class="bg-home d-flex align-items-center" style="margin-top: 50px;" style="background-color: #FFFFFF;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="row" *ngIf="errResponse">
          <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>Ops!</strong> {{ errResponse ? errResponse : 'Something wrong' }}
              <button type="button" class="btn-close" (click)="closeAlert()"> </button>
            </div>
          </div>
        </div>
        <div class="bg-white shadow-lg rounded">
          <div class="card border-0">
            <form class="card-body" [formGroup]="loginAgentFg" (ngSubmit)="onSubmitAgent()">
              <div class="row">
                <div class="col-12 text-center">
                  <img src="assets/images/new-logo/prima-new-logo.png" class="l-dark" width="50%" alt="">
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Branch Code <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i class="fa fa-building-o fea icon-sm icons"></i>
                      <input type="text" class="form-control ps-5" placeholder="Branch Code" required="" formControlName="branchCode">
                    </div>
                    <small class="text-danger" *ngIf="validatingLoginAgent.branchCode.invalid && validatingLoginAgent.branchCode.touched">Agent Code is required</small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Username <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i class="fa fa-envelope-o fea icon-sm icons"></i>
                      <input type="text" class="form-control ps-5" placeholder="Username" required="" formControlName="username">
                    </div>
                    <small class="text-danger" *ngIf="validatingLoginAgent.username.invalid && validatingLoginAgent.username.touched">username is required</small>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i class="fa fa-key fea icon-sm icons"></i>
                      <input [type]="fieldTextType ? 'text' : 'password'" class="form-control ps-5" placeholder="Pasword" formControlName="password" required="">
                      <a href="javascript:void(0);" (click)="toggleFieldTextType()">
                        <i class="fa fea icon-sm form-icon-right" [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"></i>
                      </a>
                    </div>
                    <small class="text-danger" *ngIf="validatingLoginAgent.password.invalid && validatingLoginAgent.password.touched">Password is required</small>
                  </div>
                </div>
                <div class="col-md-12">
                    <ngx-recaptcha2 #captchaElem
                      [siteKey]="siteKey"
                      [useGlobalDomain]="true"
                      [size]="size"
                      [theme]="theme"
                      [type]="type"
                      formControlName="recaptcha">
                    </ngx-recaptcha2>
                </div>
                <div class="col-md-12">
                  <div class="d-flex justify-content-end">
                    <div class="mb-3">
                      <small><a href="javascript:void(0)" (click)="navigated('forgot-password')" class="text-primary">Forgot password ?</a></small>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary" [disabled]="preloader">
                      <span *ngIf="preloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Login
                    </button>
                  </div>
                </div>
                <div class="col-12 text-center">
                  <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                    href="javascript:void(0)" (click)="routeHelper.navigated('register-agent')" class="text-primary">Sign Up</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
