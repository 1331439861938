import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationServices } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private authenticationServices: AuthenticationServices
  ) {}

  forgotForm = new FormGroup({
    email: new FormControl('', Validators.required)
  });

  errResponse;
  successResponse;
  preloader: boolean;

  siteKey :string

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  navigated(str: string) {
    this.router.navigate([str]);
  }

  closeAlert(type: string) {
    if (type === 'error') {
      this.errResponse = null;
    } else {
      this.successResponse = null;
    }
  }

  get validating() {
    return this.forgotForm.controls;
  }

  async onSubmit() {
    this.preloader = true;
    const param = {
      email: this.forgotForm.value.email,
    }

    if (this.forgotForm.valid) {
      await this.authenticationServices
        .forgotPassword(param)
        .pipe(first())
        .subscribe(
          (data) => {
            this.successResponse = data.message
            this.preloader = false;
          },
          (error) => {
            this.errResponse = error.message;
            this.preloader = false;
          }
        );
    }
  }
}
