import { ProfileDataDto } from 'src/app/shared/dto/admin/profile.dto';
import { StorageDataService } from 'src/app/helpers/storageData.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationServices } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationServices: AuthenticationServices, private storageDataService: StorageDataService) {
    this.profile = this.storageDataService.getProfileStorage();
  }

  profile: ProfileDataDto;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          if (!this.profile.customer) {
            this.authenticationServices.logout(false);
          } else {
            this.authenticationServices.logout(true);
          }
        }
        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
