import { AuthMemberPayloadDto } from './../shared/dto/auth.dto';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthData, AuthAgentPayloadDto, RegisterUser, RegisterAgent } from '../shared/dto/auth.dto';
import { EncryptDataService } from '../helpers/encrypt.service';
import { dataEnum } from '../helpers/data.enum';
import { CommonResponse } from './api-response.dto';

@Injectable({
  providedIn: 'root',
})

export class AuthenticationServices {
  private currentUserSubject: BehaviorSubject<AuthData>;
  public currentUser: Observable<AuthData>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private encryptDataService: EncryptDataService
  ) {

    try{
      this.currentUserSubject = new BehaviorSubject<AuthData>(
        JSON.parse(this.encryptDataService.decryptData(localStorage.getItem(dataEnum.auth)))
      );
      this.currentUser = this.currentUserSubject.asObservable();
    }catch (err) {
      console.log('Error: ', err?.message);
    }
  }

  public get currentUserValue(): AuthData {
    return this.currentUserSubject.value;
  }

  getMenu(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/menu', {
      params: param,
    });
  }

  authentication(payload: AuthMemberPayloadDto): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.apiUrl}/authtentication`,
      payload,
      this.httpOptions
    );
  }

  login(payload: AuthAgentPayloadDto): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.apiUrl}/authtentication`,
      payload,
      this.httpOptions
    );
  }

  loginAgent(payload: AuthAgentPayloadDto): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/authtentication/agent`,
      payload,
      this.httpOptions
    );
  }

  registerUser(payload: RegisterUser): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/registration`,
      payload,
      this.httpOptions
    );
  }

  registerAgent(payload: RegisterAgent): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/registration/agent`,
      payload,
      this.httpOptions
    );
  }

  forgotPassword(param) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/forgot-password`,
        param,
        this.httpOptions
      );
  }

  resetPassword(param) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/reset-password`,
        param,
        this.httpOptions
      );
  }

  checkToken(param) {
    return this.http.post<CommonResponse>(`${environment.apiUrl}/check-token`,
        param,
        this.httpOptions
      );
  }

  logout(isCustomer: boolean): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem(dataEnum.auth);
    localStorage.removeItem(dataEnum.profile);
    localStorage.removeItem(dataEnum.ekyc);
    localStorage.removeItem(dataEnum.menu);
    if (isCustomer) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/agent-login']);
    }
  }
}
