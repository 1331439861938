<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
  <div class="modal-dialog custom-alert" role="document">
    <div class="modal-content">
      <div *ngIf="message?.type == 'confirm'" class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center confirm-message">{{message.text}}</p>
          </div>
        </div>
        <div class="text-center" style="padding-bottom: 15px; padding-top: 25px;">
          <button type="button" (click)="message.yesFn()" class="btn btn-outline-primary"
            style="margin-right: 10px;">Yes</button>
          <button type="button" (click)="message.noFn()" class="btn btn-danger">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
