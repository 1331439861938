import { ReportAdminBloc } from 'src/app/shared/bloc/admin/report.admin.bloc';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../services/remittance/print-receipt.service';
import { DateFormatService } from '../../shared/data/date-format.service';

@Component({
  selector: 'app-print-finance-supervisor-report',
  templateUrl: './print-finance-supervisor-report.component.html',
  styleUrls: ['./print-finance-supervisor-report.component.css'],
})
export class PrintFinanceSupervisorReportComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  financeSupervisorReportData;

  constructor(
    route: ActivatedRoute,
    private printService: PrintService,
    private readonly reportAdminBloc: ReportAdminBloc,
    public dateFormatService: DateFormatService
  ) {}

  ngOnInit() {
    this.financeSupervisorReportData =
      this.reportAdminBloc.financeSupervisorReportData;
    console.log(this.financeSupervisorReportData, 27);
    this.printService.onDataReady();
  }
}
