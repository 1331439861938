import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { SidebarService } from './sidebar.service';

import * as $ from 'jquery';
import { StorageDataService } from '../../helpers/storageData.service';
import { PrintService } from '../../services/remittance/print-receipt.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  availableMenu: any;
  getMenuByRole: any;
  constructor(
    public sidebarservice: SidebarService,
    public printService: PrintService,
    private router: Router,
    private storageDataService: StorageDataService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (
        event instanceof NavigationEnd &&
        $(window).width() < 1025 &&
        (document.readyState === 'complete' || false)
      ) {
        this.toggleSidebar();
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        // console.log(event.error);
      }
    });
  }

  toggleSidebar(): void {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($('.wrapper').hasClass('nav-collapsed')) {
      // unpin sidebar when hovered
      $('.wrapper').removeClass('nav-collapsed');
      $('.sidebar-wrapper').unbind('hover');
    } else {
      $('.wrapper').addClass('nav-collapsed');
      $('.sidebar-wrapper').hover(
        function () {
          $('.wrapper').addClass('sidebar-hovered');
        },
        function () {
          $('.wrapper').removeClass('sidebar-hovered');
        }
      );
    }
  }

  // tslint:disable-next-line: typedef
  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar(): void {
    this.sidebarservice.setSidebarState(true);
  }

  async ngOnInit(): Promise<void> {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.availableMenu = this.storageDataService.getMenuStorage();
    this.getMenuByRole = this.availableMenu[Object.keys(this.availableMenu)[0]];

    await this.setDisplayAllMenu(this.menuItems);
    await this.setDisplayMenu(this.menuItems);
    $.getScript('./assets/js/app-sidebar.js');
  }

  setDisplayAllMenu(menuItem) {
    for (const menu of menuItem) {
      const findMenu = this.getMenuByRole.find((e) => {
        return String(e.key) === String(menu.menuKey);
      });
      if (findMenu) {
        if (findMenu.read) {
          menu['display'] = true;
        } else {
          menu['display'] = false;
        }
      } else {
        menu['display'] = false;
      }
      if (menu.submenu.length > 0) {
        this.setDisplayAllMenu(menu.submenu);
      }
    }
  }

  setDisplayMenu(menuItem) {
    for (const menu of menuItem) {
      const hasSubmenu = menu.submenu.length > 0 ? true : false;
      if (hasSubmenu) {
        const hasMenu = menu.submenu.find((e) => {
          return e.display === true;
        });
        if (hasMenu) {
          menu['display'] = true;
        }
      }
      this.setDisplayMenu(menu.submenu);
    }
  }
}
