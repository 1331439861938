import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router, private location: Location) {}

  printDocument(documentName: string) {
    if (documentName === 'remittance-print') {
      this.isPrinting = false;
      this.router.navigate(['print-receipt']);
    } else if (documentName === 'finance-supervisor') {
      this.isPrinting = true;
      this.router.navigate(['print-finance-supervisor']);
    }
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.location.back();
    });
  }
}
