import { CustomerProfileServices } from 'src/app/services/customer/profile.service';
import { ProfileServices } from './../../../services/profile/profile.service';
import { ProfileDataDto } from './../../dto/admin/profile.dto';
import { dataEnum } from 'src/app/helpers/data.enum';
import { CommonResponse } from './../../../services/api-response.dto';
import { AuthMemberPayloadDto, AuthAgentPayloadDto } from './../../dto/auth.dto';
import { AuthenticationServices } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthBloc {
  constructor(
    private authenticationServices: AuthenticationServices,
    private profileServices: ProfileServices,
    private customerProfileServices: CustomerProfileServices,
  ) {}

  async authMember(payload: AuthMemberPayloadDto): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.authenticationServices.authentication(payload).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async authAgent(payload: AuthAgentPayloadDto): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.authenticationServices.login(payload).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }


  async getAccountInfo(): Promise<ProfileDataDto> {
    return new Promise(async (resolve) => {
      this.profileServices
        .getAccountInfo()
        .subscribe(
          (result) => {
            resolve(result.data);
          },
          (error) => {
            resolve(null);
          }
        );
    });
  }

  async getMenu(): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.authenticationServices.getMenu({}).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async getEkyc(): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.customerProfileServices.getEkyc(null).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }
}
