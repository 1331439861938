<section class="bg-half-170 d-table w-100"
  style="background: url('assets/images/home-bg-4.jpg'); background-repeat: no-repeat; background-size: 100%;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mt-4">
          <h1 class="display-4 fw-bold title-white mb-3">Connected <br><span class="text-blue-2">Anytime and
              anywhere</span></h1>
          <p class="para-desc text-black">Simple, reliable, and fast transfer</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<section class="section" style="background-color: #FFFFFF;">
  <div class="container mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/travel/3.jpg" class="img-fluid rounded shadow-md" alt="">
      </div>
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">Easy steps <br> to send money online</h4>
          <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem architecto maiores,
            assumenda tenetur qui quasi error esse accusantium incidunt quae, iste vel? Rem deleniti doloribus,
            repudiandae cupiditate reprehenderit repellat nihil!</p>
        </div>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item rounded shadow">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button border-0 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Sign up and verify your indentity
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse border-0 collapse show" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div class="accordion-body text-muted bg-white">
                Sign up on our website. Take a picture of your identity and a picture of you holding your identity.
                And
                wait for our team to verify it.
              </div>
            </div>
          </div>

          <div class="accordion-item rounded shadow mt-2">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Select country and enter amount
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse border-0 collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body text-muted bg-white">
                Choose destination country, how to receive money, and how much you want to send. All the details will
                be
                shown here - rates, fees and estimate arrive time.
              </div>
            </div>
          </div>

          <div class="accordion-item rounded shadow mt-2">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Enter recipient’s details
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse border-0 collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body text-muted bg-white">
                Fill in recipient’s details. It will be saved, so you can use it next time with one click. See common
                fields for
              </div>
            </div>
          </div>

          <div class="accordion-item rounded shadow mt-2">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Pay for your transfer and relax
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse border-0 collapse" aria-labelledby="headingFour"
              data-bs-parent="#accordionExample">
              <div class="accordion-body text-muted bg-white">
                Pay by transfering IDR to our bank account in Indonesia. And confirm your payment. It’s time to relax
                and we’ll do rest for you
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section" style="background-color: #FFFFFF;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Agents</h4>
          <p class="text-muted para-desc mx-auto mb-0">This is the list of our agents in the world</p>
        </div>
      </div>
    </div>

    <div class="row mt-4 pt-2 justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <ul class="nav nav-pills rounded nav-justified flex-column flex-sm-row" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link rounded active" id="brunei-tab" data-bs-toggle="pill" href="#brunei" role="tab"
              aria-controls="brunei" aria-selected="false">
              <div class="text-center py-2">
                <h6 class="mb-0">Brunei Darussalam</h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="brunei" role="tabpanel" aria-labelledby="pills-dayone-tab">
            <div class="row">
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-1 content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Maji</a></h4>
                        <p class="text-muted location-time">
                          <span class="text-dark h6"> Address: </span> <br>
                          <span>No. 31 Basement Seria Plaza Jalan Sultan Omar Ali Seria BD 2733, Brunei
                            Darussalam</span> <br>
                          <span class="text-dark h6">Telp: <br></span>
                          <span>+673 3224560</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-1 content">
                        <h4><a href="javascript:void(0)" class="text-dark title">Wadi</a></h4>
                        <p class="text-muted location-time">
                          <span class="text-dark h6"> Address: </span> <br>
                          <span>Bangunan Pusat Komersial Dan Perdagangan Bumiputra Tingkat 1 No. 7 Jalan Cator, Bandar
                            Seri Begawan, BS 8670 Negara Brunei Darussalam</span> <br>
                          <span class="text-dark h6">Telp: <br></span>
                          <span>+673 2223538</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mt-4 pt-2">
                <div class="card event-schedule rounded border">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-1 content">
                        <h4><a href="javascript:void(0)" class="text-dark title">KH</a></h4>
                        <p class="text-muted location-time">
                          <span class="text-dark h6"> Address: </span> <br>
                          <span>No. 6 Ground Floor Lot 5073 Abdul Wahab Complex Kampong Petani Tutong TA 2341 Brunei
                            Darussalam</span> <br>
                          <span class="text-dark h6">Telp: <br></span>
                          <span>+673 4261437</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section" style="background-color: #FFFFFF;">
  <div class="container mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">Became our agent ?</h4>
          <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression and
            facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is
            relatively realistic.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a routerLink="/register-agent" class="btn btn-outline-primary">Start Now <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/illustrator/app_development_SVG.svg" alt="">
      </div>
    </div>
  </div>
</section>
<section class="section" style="background-color: #FFFFFF;">
  <div class="container mt-100 mt-60" style="margin-bottom: 50px;">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What our customers are saying us?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Morbi convallis bibendum urna ut viverra. Maecenas quis consequat
            libero</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="tiny-single-item">
        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" It seems that only fragments of the original text remain in the Lorem
            Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have
            originated
            in the 16th century. "</p>
          <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
        </div>
        <!--end customer testi-->

        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" The advantage of its Latin origin and the relative meaninglessness of
            Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from
            the
            layout. "</p>
          <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
        </div>
        <!--end customer testi-->

        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" There is now an abundance of readable dummy texts. These are usually
            used
            when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are
            often
            amusing and tell short, funny or nonsensical stories. "</p>
          <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
        </div>
        <!--end customer testi-->

        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" According to most sources, Lorum Ipsum can be traced back to a text
            composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all
            the instances of the unusual word 'consectetur' he could find "</p>
          <img src="assets/images/client/04.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
        </div>
        <!--end customer testi-->

        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" It seems that only fragments of the original text remain in the Lorem
            Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have
            originated
            in the 16th century. "</p>
          <img src="assets/images/client/05.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
        </div>
        <!--end customer testi-->

        <div class="tiny-slide client-testi text-center">
          <p class="text-muted h6 fst-italic">" It seems that only fragments of the original text remain in the Lorem
            Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted
            at various positions within the text. "</p>
          <img src="assets/images/client/06.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
            alt="">
          <ul class="list-unstyled mb-0 mt-3">
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
          </ul>
          <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
        </div>
        <!--end customer testi-->
      </div>
    </div>
  </div>
</section>

<section class="section" style="background-color: #FFFFFF;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Partner</h4>
          <p class="text-muted para-desc mb-0 mx-auto">
            The network of Prima Express is growing as it partners with more banks to improve its services.
          </p>
        </div>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-md-3">
        <img src="assets/images/partners/atmbersama.png" alt="atm bersama logo">
      </div>
      <div class="col-md-3">
        <img src="assets/images/partners/landbank.png" alt="land bank logo">
      </div>
      <div class="col-md-3">
        <img src="assets/images/partners/moneygram.png" alt="money gram logo">
      </div>
      <div class="col-md-3">
        <img src="assets/images/partners/logo_mmfb.png" alt="mmfb logo">
      </div>
    </div>
  </div>
</section>
