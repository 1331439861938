import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  shortFormatDate(date: string) {
    return moment(date).utc(true).format('YYYY-MM-DD');
  }

  longFormatDate(date: string) {
    return moment(date).utc(true).format('D MMM YYYY');
  }

  longFormatDateWithTime(date: string) {
    return moment(date).utc(true).format('D MMM YYYY HH:mm:ss');
  }

  longFormatDateWithTimeNoTz(date: string) {
    return moment(date).utc(false).format('D MMM YYYY HH:mm:ss');
  }

  longFormatDateWithTimeUsingTz(date: string) {
    return moment(date).utc(true).format('D MMM YYYY HH:mm:ss');
  }
}
