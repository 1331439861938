<section class="bg-home d-flex align-items-center" style="background-color: #FFFFFF;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="me-lg-5">
          <img src="assets/images/user/recovery.svg" class="img-fluid d-block mx-auto" alt="">
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12" *ngIf="errResponse">
                <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errResponse">
                  <strong>Ops!</strong> {{ errResponse ? errResponse : 'Something wrong' }}
                  <button type="button" class="btn-close" (click)="closeAlert('error')" aria-label="Close"> </button>
                </div>
              </div>
              <div class="col-md-12" *ngIf="successResponse">
                <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="successResponse">
                  <strong>Done!</strong> {{ successResponse ? successResponse : '' }}
                  <button type="button" class="btn-close" (click)="closeAlert('success')" aria-label="Close"> </button>
                </div>
              </div>
            </div>
            <h4 class="card-title text-center">Recover Account</h4>

            <form class="login-form mt-4" [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-muted">Please enter your email address. You will receive a link to create a new
                    password via email.</p>
                  <div class="mb-3">
                    <label class="form-label">Email address <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i class="fa fa-envelope-o fea icon-sm icons"></i>
                      <input type="email" class="form-control ps-5" placeholder="Enter Your Email Address" formControlName="email"
                        required="">
                    </div>
                    <small class="text-danger" *ngIf="validating.email.invalid && validating.email.touched">Email is required</small>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary" [disabled]="preloader">
                      <span *ngIf="preloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Send
                    </button>
                  </div>
                </div>
                <div class="mx-auto mb-0 mt-3">
                  <p class="mb-0 mt-3"><small class="text-dark me-2">Remember your account ?</small> <a
                    href="javascript:void(0)" (click)="navigated('login')" class="text-primary">Sign In</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
