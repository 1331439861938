import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {  Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationServices } from '../../../services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private authenticationServices: AuthenticationServices
  ) {
    this.token = this.actRoute.snapshot.params.id;
  }

  token: string;
  validToken;
  fieldTextTypePassword: boolean;
  fieldTextTypeConfirmPassword: boolean;
  afterSubmit;

  errResponse;
  successResponse;
  preloader: boolean;

  resetForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    passwordConfirmation: new FormControl('', Validators.required),
  });

  async ngOnInit(): Promise<void> {
    this.afterSubmit = false;
    window.scrollTo(0, 0);
    if (!this.token) {
      this.navigated('/');
    }
    await this.checkToken(this.token);
  }

  closeAlert(type: string) {
    if (type === 'error') {
      this.errResponse = null;
    } else {
      this.successResponse = null;
    }
  }

  toggleFieldTextType() {
    this.fieldTextTypePassword = !this.fieldTextTypePassword;
  }

  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeConfirmPassword = !this.fieldTextTypeConfirmPassword;
  }

  navigated(str: string) {
    this.router.navigate([str]);
  }

  get validating() {
    return this.resetForm.controls;
  }

  async checkToken(paramToken: string) {
    const param = {
      token: paramToken,
    };
    await this.authenticationServices
        .checkToken(param)
        .pipe(first())
        .subscribe(
          (data) => {
            this.validToken = true;
          },
          (error) => {
            this.navigated('login');
          }
        );
  }

  async onSubmit() {
    const password = String(this.resetForm.value.password);
    const passwordConfirmation = String(this.resetForm.value.passwordConfirmation);
    if (this.resetForm.valid && (password === passwordConfirmation)) {
      this.preloader = true;
      const param = {
        token: this.token,
        email: this.resetForm.value.email,
        password: this.resetForm.value.password,
        passwordConfirmation: this.resetForm.value.passwordConfirmation
      }
      await this.authenticationServices
        .resetPassword(param)
        .pipe(first())
        .subscribe(
          (data) => {
            this.afterSubmit = true;
            this.preloader = false;
          },
          (error) => {
            this.afterSubmit = false;
            this.preloader = false;
            this.errResponse = error.message
          }
        );
    } else {
      this.errResponse = 'Please fill with correct data or password confirmation is not match';
    }
  }
}
