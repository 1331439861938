import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  data: any;
  tinySliderConfig: any;

  remittanceFg = new FormGroup({
    transferFrom: new FormControl('', Validators.required),
    transferTo: new FormControl('', Validators.required),
    rateValue: new FormControl(''),
  });


  ngOnInit(): void {
    $.getScript('./assets/js/tiny-slider.js');
    $.getScript('./assets/js/plugins.init.js');

    this.router.events.subscribe((x) => {
      if (x instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    // this.tinySliderConfig = {
    //   container: '.tiny-single-item',
    //   items: 1,
    //   controls: false,
    //   mouseDrag: true,
    //   loop: true,
    //   rewind: true,
    //   autoplay: true,
    //   autoplayButtonOutput: false,
    //   autoplayTimeout: 3000,
    //   navPosition: 'bottom',
    //   speed: 400,
    //   gutter: 16,
    // };
  }
}
