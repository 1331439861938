import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { toFormData } from '../../helpers/formdata.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerBranchService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getInternalBranch(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.apiUrl}/internal-branch`,
      {}
    );
  }
}
