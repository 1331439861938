import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { StorageDataService } from './storageData.service';
import { ProfileDataDto } from '../shared/dto/admin/profile.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storageDataService: StorageDataService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentUser: ProfileDataDto = this.storageDataService.getProfileStorage();
    if (currentUser && String(route.data.role) === String(currentUser.userType)) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
