import { EncryptDataService } from 'src/app/helpers/encrypt.service';
import { dataEnum } from 'src/app/helpers/data.enum';
import { CustomerBloc } from './../../shared/bloc/customer/customer.bloc';
import { AuthBloc } from './../../shared/bloc/auth/auth.bloc';
import { Component, DoCheck, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { AuthenticationServices } from '../../services/authentication.service';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { StorageDataService } from 'src/app/helpers/storageData.service';
import { ProfileDataDto } from '../../shared/dto/admin/profile.dto';

@Component({
  selector: 'app-member-layout',
  templateUrl: './member-layout.component.html',
  styleUrls: ['./member-layout.component.scss'],
})
export class MemberLayoutComponent implements OnInit, DoCheck {
  constructor(
    private router: Router,
    public authenticationServices: AuthenticationServices,
    private storageDataService: StorageDataService,
    public sidebarService: SidebarService,
    private authBloc: AuthBloc,
    private encryptDataService: EncryptDataService
  ) {}

  dateNow;
  toggleMemberMenu;
  activeMenu;
  dataCustomer: ProfileDataDto = null;
  statusEkyc = null;

  async ngOnInit(): Promise<void> {
    this.dateNow = moment().format('YYYY');
    this.toggleMemberMenu = false;
    this.activeMenu = '/member/account';
    this.dataCustomer = this.storageDataService.getProfileStorage();
    this.statusEkyc = this.storageDataService.getEkycStorage();
  }

  ngDoCheck() {
    this.statusEkyc = this.storageDataService.getEkycStorage();
    this.dataCustomer = this.storageDataService.getProfileStorage();
  }

  navigated(url: string) {
    this.toggleMenu();
    this.activeMenu = url;
    this.router.navigate([url]);
  }

  getActiveMenu(str: string) {
    return String(this.activeMenu) === str ? true : false;
  }

  toggleMenu() {
    this.toggleMemberMenu = !this.toggleMemberMenu;
  }

  logout(): void {
    this.authenticationServices.logout(true);
  }
}
