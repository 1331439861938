import { PrintFinanceSupervisorReportComponent } from './admin-site/print-finance-supervisor-report/print-finance-supervisor-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import {
  fullRoutes,
  mainpageRoute,
  memberRoutes,
} from './shared/routes/full-layout.routes';
import { contentRoutes } from './shared/routes/content-layout.routes';
import { AuthGuard } from './helpers/auth.guard';
import { dataEnum } from './helpers/data.enum';
import { ReceiptComponent } from './admin-site/receipt/receipt.component';
import { MemberLayoutComponent } from './layouts/member/member-layout.component';
import { MainPageLayoutComponent } from './layouts/mainpage/mainpage-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { LoginAgentComponent } from './mainpage-site/pages/login-agent/login-agent.component';
import { RegisterAgentComponent } from './mainpage-site/pages/register-agent/register-agent.component';
import { ForgotPasswordComponent } from './mainpage-site/pages/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: 'print-receipt',
    component: ReceiptComponent,
  },
  {
    path: 'print-finance-supervisor',
    component: PrintFinanceSupervisorReportComponent,
  },
  {
    path: '',
    component: LoginAgentComponent,
  },
  {
    path: 'register-agent', 
    component: RegisterAgentComponent
  },
  {
    path: 'forgot-password', 
    component: ForgotPasswordComponent
  },
  {
    path: '',
    component: MemberLayoutComponent,
    data: {
      role: dataEnum.customer,
    },
    canActivate: [AuthGuard],
    children: memberRoutes,
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'full Views', role: dataEnum.admin },
    children: fullRoutes,
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'content Views', role: dataEnum.admin },
    children: contentRoutes,
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
