import { AddBeneficieryDto } from './../../shared/dto/customer/beneficieries';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { toFormData } from '../../helpers/formdata.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerBeneficieriesService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getBeneficieries(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.apiUrl}/customer/beneficiary`,
      { params: {} }
    );
  }

  addBeneficieries(param: AddBeneficieryDto): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.apiUrl}/customer/beneficiary`,
      param,
      this.httpOptions
    );
  }

  updateBeneficieries(param: any, uuid: string): Observable<CommonResponse> {
    return this.http.put<CommonResponse>(
      `${environment.apiUrl}/administration/customer/beneficiary/${uuid}`,
      param,
      this.httpOptions
    );
  }

  deleteBeneficieries(uuid: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      `${environment.apiUrl}/administration/customer/beneficiary/${uuid}`,
      this.httpOptions
    );
  }

  getBeneficieriesByCustomer(param: any, uuid: string): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(
      `${environment.apiUrl}/administration/customer/beneficiary/${uuid}`,
      { params: param }
    );
  }
}
