<div *ngIf="afterSubmit">
  <section class="bg-home bg-light d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="text-center">
            <img src="assets/images/new-logo/prima-express-dark.png" class="l-dark" height="84" alt="">
            <!-- <div class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
              style="height: 90px; width:90px;">
              <i class="uil uil-thumbs-up align-middle h1 mb-0"></i>
            </div> -->
            <h1 class="my-4 fw-bold">Thank You</h1>
            <p class="text-muted para-desc mx-auto">
              Your account has been recover by your self. You can access your account again.
            </p>
            <a href="javascript:void(0)" (click)="navigated('login')" class="btn btn-soft-primary mt-3">Go To Login
              Page</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="!afterSubmit">
  <section class="bg-home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-md-6">
          <div class="me-lg-5">
            <img src="assets/images/illustrator/working_with_computer.svg" class="img-fluid d-block mx-auto" alt="">
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="card shadow rounded border-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12" *ngIf="errResponse">
                  <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errResponse">
                    <strong>Ops!</strong> {{ errResponse ? errResponse : 'Something wrong' }}
                    <button type="button" class="btn-close" (click)="closeAlert('error')" aria-label="Close"> </button>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="successResponse">
                  <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="successResponse">
                    <strong>Done!</strong> {{ successResponse ? successResponse : '' }}
                    <button type="button" class="btn-close" (click)="closeAlert('success')" aria-label="Close"> </button>
                  </div>
                </div>
              </div>
              <h4 class="card-title text-center">Reset Account</h4>
              <form class="mt-4" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-12">
                    <p class="text-muted">Please enter your new password and you can back to login.</p>
                    <div class="mb-3">
                      <label class="form-label">Email address <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-envelope-o fea icon-sm icons"></i>
                        <input type="email" class="form-control ps-5" placeholder="Enter Your Email Address"
                          formControlName="email" required="">
                      </div>
                      <small class="text-danger" *ngIf="validating.email.invalid && validating.email.touched">Email is
                        required</small>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">New Password <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-key fea icon-sm icons"></i>
                        <input [type]="fieldTextTypePassword ? 'text' : 'password'" class="form-control ps-5"
                          placeholder="Pasword" formControlName="password" required="">
                        <a href="javascript:void(0);" (click)="toggleFieldTextType()">
                          <i class="fa fea icon-sm form-icon-right"
                            [ngClass]="{ 'fa-eye-slash': !fieldTextTypePassword, 'fa-eye': fieldTextTypePassword }"></i>
                        </a>
                      </div>
                      <small class="text-danger"
                        *ngIf="validating.password.invalid && validating.password.touched">Password is required</small>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-key fea icon-sm icons"></i>
                        <input [type]="fieldTextTypeConfirmPassword ? 'text' : 'password'" class="form-control ps-5"
                          placeholder="Pasword" formControlName="passwordConfirmation" required="">
                        <a href="javascript:void(0);" (click)="toggleFieldTextTypeConfirm()">
                          <i class="fa fea icon-sm form-icon-right"
                            [ngClass]="{ 'fa-eye-slash': !fieldTextTypeConfirmPassword, 'fa-eye': fieldTextTypeConfirmPassword }"></i>
                        </a>
                      </div>
                      <small class="text-danger"
                        *ngIf="validating.passwordConfirmation.invalid && validating.passwordConfirmation.touched">Confirm
                        Password is required</small>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary" [disabled]="preloader">
                        <span *ngIf="preloader" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Send
                      </button>
                    </div>
                  </div>
                  <div class="mx-auto mb-0 mt-3">
                    <p class="mb-0 mt-3"><small class="text-dark me-2">Remember your password ?</small> <a
                        href="javascript:void(0)" (click)="navigated('login')" class="text-primary">Log In</a></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
