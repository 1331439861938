<section class="section mt-60" *ngIf="!thanksPage" style="background-color: #FFFFFF;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title">Register</h4>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errRegister">
              <strong>Ops!</strong> {{ messageResponse ? messageResponse : 'Something wrong' }}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> </button>
            </div>
            <div class="custom-form mt-3">
              <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <p id="error-msg" class="mb-0"></p>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Your Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-user fea icon-sm icons"></i>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name"
                          formControlName="name">
                      </div>
                      <small class="text-danger" *ngIf="validating.name.invalid && validating.name.touched">is
                        required</small>
                      <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.name }}</small>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Phone Number <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-phone fea icon-sm icons"></i>
                        <input name="name" id="name" type="number" class="form-control ps-5" placeholder="Phone Number"
                          formControlName="phone">
                      </div>
                      <small class="text-danger" *ngIf="validating.phone.invalid && validating.phone.touched">is
                        required</small>
                      <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.phone }}</small>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-envelope-o fea icon-sm icons"></i>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email"
                          formControlName="email">
                      </div>
                      <small class="text-danger" *ngIf="validating.email.invalid && validating.email.touched">is
                        required</small>
                      <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.email }}</small>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Password <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-key fea icon-sm icons"></i>
                        <input [type]="fieldTextTypePassword ? 'text' : 'password'" class="form-control ps-5" placeholder="Pasword" formControlName="password" required="">
                        <a href="javascript:void(0);" (click)="toggleFieldTextType()">
                          <i class="fa fea icon-sm form-icon-right" [ngClass]="{ 'fa-eye-slash': !fieldTextTypePassword, 'fa-eye': fieldTextTypePassword }"></i>
                        </a>
                      </div>
                      <small class="text-danger" *ngIf="validating.password.invalid && validating.password.touched">Password is required</small>
                      <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.password }}</small>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i class="fa fa-key fea icon-sm icons"></i>
                        <input [type]="fieldTextTypeConfirmPassword ? 'text' : 'password'" class="form-control ps-5" placeholder="Pasword" formControlName="passwordConfirmation" required="">
                        <a href="javascript:void(0);" (click)="toggleFieldTextTypeConfirm()">
                          <i class="fa fea icon-sm form-icon-right" [ngClass]="{ 'fa-eye-slash': !fieldTextTypeConfirmPassword, 'fa-eye': fieldTextTypeConfirmPassword }"></i>
                        </a>
                      </div>
                      <small class="text-danger" *ngIf="validating.passwordConfirmation.invalid && validating.passwordConfirmation.touched">Password is required</small>
                      <small class="text-danger" *ngIf="errRegister">{{ returnFormValidation.passwordConfirmation }}</small>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [value]="1"
                          formControlName="confirmRegister" (change)="confirRegister($event)">
                        <label class="form-check-label" for="flexSwitchCheckDefault">
                          By registering, I accept the <a href="javascript:void(0)"
                            (click)="navigated('term-of-use')">Terms of Service</a> and <a href="javascript:void(0);"
                            (click)="navigated('privacy-policy')">Privacy Policy</a>.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary" [disabled]="!confirmed || preloader">
                        <span *ngIf="preloader" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                        Register
                      </button>
                      <!-- <button type="submit" id="submit" name="send" class="btn btn-primary" [disabled]="preloader && confirmed">Register</button> -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <p>&nbsp;</p>
            Already register ? <a href="javascript:void(0)" (click)="navigated('/login')">Login here</a>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <img src="assets/images/hosting/deal-hend.svg" class="img-fluid" alt="">
      </div>
    </div>
  </div>
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Became our Agent</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam tempora neque, voluptatibus minus eos
            inventore ab veniam dignissimos assumenda numquam! Natus iste nesciunt obcaecati dolore minus doloribus
            repellat tempore ea.
          </p>
          <a routerLink="/register-agent" class="btn btn-primary mt-4"><i class="uil uil-bag-alt"></i> Register Now</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-home bg-light d-flex align-items-center" *ngIf="thanksPage">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 col-12">
        <img src="assets/images/illustrator/envelope.svg" class="img-fluid mx-auto d-block" alt="">
      </div>

      <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Thank you <br> for register your account.</h4>
          <p class="text-muted para-desc mb-0">Please check your email to verify your account.</p>
          <div class="my-4">
            <a (click)="navigated('/')" class="btn btn-outline-primary m-1"><i class="uil uil-home"></i> Back to
              Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
