import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const fullRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../admin-site/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'remittance',
    loadChildren: () =>
      import('../../admin-site/new-remittance/new-remittance.module').then(
        (m) => m.NewRemittanceModule
      ),
  },
  {
    path: 'remittance-customer',
    loadChildren: () =>
      import(
        '../../admin-site/remittance-customer/remittance-customer.module'
      ).then((m) => m.RemittanceCustomerModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('../../admin-site/payment-digital/payment-digital.module').then(
        (m) => m.PaymentDigitalModule
      ),
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('../../admin-site/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../../admin-site/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
  },
  {
    path: 'accounting',
    loadChildren: () =>
      import('../../admin-site/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: 'master-data',
    loadChildren: () =>
      import('../../admin-site/master-data/master-data.module').then(
        (m) => m.MasterDataModule
      ),
  },
  {
    path: 'dashboard/user-profile',
    loadChildren: () =>
      import('../../user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },
];

export const memberRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../member-site/member-site.module').then(
        (m) => m.MemberModule
      ),
  },
];

export const mainpageRoute: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../mainpage-site/mainpage-site.module').then(
        (m) => m.MainpageSiteModule
      ),
  },
];
