<header id="topnav" class="defaultscroll sticky nav-sticky box-shadow-user" style="background-color: #0A1931;">
  <div class="container">
    <a class="logo" (click)="navigated('/member/account')">
      <span class="logo-light-mode">
        <img src="assets/images/new-logo/prima-express-dark.png" class="l-dark" height="56" alt="">
      </span>
      <img src="assets/images/new-logo/prima-express-dark.png" height="56" class="logo-dark-mode" alt="">
    </a>

    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>

    <div id="navigation" [ngStyle]="{'display': toggleMemberMenu ? 'block' : 'none'}">
      <ul class="navigation-menu nav-light">
        <li><a href="javascript:void(0)" (click)="navigated('/')" class="text-white">Home </a></li>
        <li><a href="javascript:void(0)" (click)="navigated('/member/account')" class="text-white">Account </a></li>
        <li><a href="javascript:void(0)" (click)="navigated('/member/profile')" class="text-white">Detail Profile</a></li>
        <li><a href="javascript:void(0)" (click)="navigated('/member/beneficiery')" class="text-white">Beneficieries</a></li>
        <li><a href="javascript:void(0)" (click)="logout()" href="javascript:void()" class="text-white" >Log Out</a></li>
      </ul>
    </div>
  </div>
</header>

<section class="section" style="background-color: #ffffff !important;">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar ms-lg-4">
          <div class="card-body p-0">
            <div class="text-center">
              <span class="bg-soft-primary d-block py-2 rounded shadow text-center h6 mb-0 text-muted" style="font-weight: 500 !important;">
                Hallo!
              </span>
              <div class="mt-4">
                <ngx-avatar class="my-avatar" [size]="80" name="{{ dataCustomer.customer.name }}"></ngx-avatar>
                <p class="text-primary mt-2 mb-0 d-block" style="font-size: 14pt;">{{ dataCustomer.customer.name }}</p>
                <p class="mb-0 text-muted" style="font-size: 12px;">{{ dataCustomer.email }}</p>
                <!-- <div class="text-center">
                  <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div> -->
                <div>
                  <a (click)="navigated('member/profile')">
                    <span *ngIf="statusEkyc === 1" class="badge rounded-pill bg-success me-1"><i class="fa fa-check"></i> Verified</span>
                    <span *ngIf="statusEkyc === 9" class="badge rounded-pill bg-warning me-1"><i class="fa fa-clock-o"></i> Waiting for Approval</span>
                    <span *ngIf="statusEkyc === 0" class="badge rounded-pill bg-danger me-1"><i class="fa fa-times-circle"></i> Unverified</span>
                    <span *ngIf="statusEkyc === 3" class="badge rounded-pill bg-danger me-1"><i class="fa fa-times-circle"></i> Rejected</span>
                  </a>
                </div>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="updateProfileModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <form>
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3 text-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="col-12 mb-3">
              <label for="formFile" class="form-label">Profile Photo</label>
              <input class="form-control" type="file" id="formFile">
            </div>
            <div class="col-12 mb-3 text-end">
              <button type="button" class="btn btn-primary">Update Profile</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<footer class="footer" style="position: fixed; bottom: 0; width: 100%; z-index: 1000;">
  <div class="footer-py-30 footer-bar">
      <div class="container text-center">
          <div class="row align-items-center">
              <div class="col-sm-6">
                  <div class="text-sm-start">
                      <p class="mb-0">&copy; {{ dateNow }} Prima Express.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</footer>
