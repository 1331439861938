import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthData } from '../shared/dto/auth.dto';
import { StorageDataService } from './storageData.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageDataService: StorageDataService,) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const dataLogin: AuthData = this.storageDataService.getAuthDataStorage();
    if (dataLogin && dataLogin.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${dataLogin.accessToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
