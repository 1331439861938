import * as moment from 'moment';
import { Injectable } from '@angular/core';
import * as currency from 'currency.js';

@Injectable({
  providedIn: 'root',
})
export class CurrencyFormatService {
  currFormat2Dec(value: number): string {
    return String(
      currency(value, {
        symbol: '',
        separator: ',',
        decimal: '.',
        precision: 2,
      }).format()
    );
  }

  currFormat4Dec(value: number): string {
    return String(
      currency(value, {
        symbol: '',
        separator: ',',
        decimal: '.',
        precision: 4,
      }).format()
    );
  }
}
