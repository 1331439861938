import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse, CommonPaginateResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';

@Injectable({
  providedIn: 'root',
})
export class MasterDataBankServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getBank(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/bank/pagination', {
      params: param,
    });
  }

  manageDataBank(param: any, uuid: string) {
    if (uuid) {
      return this.http.put<CommonResponse>(
        `${environment.apiUrl}/bank/${uuid}`,
        param,
        this.httpOptions
      );
    } else {
      return this.http.post<CommonResponse>(
        `${environment.apiUrl}/bank`,
        param,
        this.httpOptions
      );
    }
  }

  deleteBank(uuid: string) {
    return this.http.delete<CommonResponse>(
      `${environment.apiUrl}/bank/${uuid}`,
      this.httpOptions
    );
  }

  getBankSelect(param: any, type: string) {
    let uri = environment.apiUrl + '/customer/bank';
    if (type === 'admin') {
      uri = environment.apiUrl + '/bank';
    }

    return this.http.get<CommonResponse>(uri, {
      params: param,
    });
  }

  getBankPaydiaSelect(param: any) {
    const uri = `${environment.apiUrl}/bank-paydia`;
    return this.http.get<CommonResponse>(uri, {
      params: param,
    });
  }
}
