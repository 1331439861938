import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationServices } from 'src/app/services/authentication.service';
import { RegisterUser } from '../../../shared/dto/auth.dto';
import { CustomerBloc } from '../../../shared/bloc/customer/customer.bloc';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  constructor(
    private router: Router,
    private authenticationServices: AuthenticationServices,
    private customerBloc: CustomerBloc
  ) {}

  registerForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    passwordConfirmation: new FormControl('', Validators.required),
    confirmRegister: new FormControl('', Validators.required),
  });

  errRegister: boolean;
  messageResponse: string;
  preloader: boolean;
  returnFormValidation: RegisterUser;
  thanksPage: boolean;
  confirmed: boolean;
  fieldTextTypePassword: boolean;
  fieldTextTypeConfirmPassword: boolean;

  async ngOnInit(): Promise<void> {
    this.errRegister = false;
    this.preloader = false;
    this.thanksPage = false;
    this.confirmed = false;

    window.scrollTo(0, 0);
  }

  toggleFieldTextType() {
    this.fieldTextTypePassword = !this.fieldTextTypePassword;
  }

  toggleFieldTextTypeConfirm() {
    this.fieldTextTypeConfirmPassword = !this.fieldTextTypeConfirmPassword;
  }

  get validating() {
    return this.registerForm.controls;
  }

  navigated(str: string) {
    this.router.navigate([str]);
  }

  async onSubmit() {
    this.preloader = true;
    const dataRegister: RegisterUser = this.registerForm.value;
    let responseData = null;
    if (this.registerForm.valid && dataRegister.confirmRegister) {
      delete dataRegister.confirmRegister;
      dataRegister.branch = '';
      responseData = await this.registerPost(dataRegister);
      if (responseData) {
        this.preloader = false;
        this.thanksPage = true;
        window.scrollTo(0, 0);
      }
    }
  }

  async registerPost(registerUser: RegisterUser) {
    return new Promise(async (resolve) => {
      this.authenticationServices.registerUser(registerUser).subscribe(
        (result) => {
          resolve(result);
          this.preloader = false;
        },
        (error) => {
          this.preloader = false;
          this.messageResponse = error.message;
          this.errRegister = true;
          this.handleValidationReturn(error.errors);
        }
      );
    });
  }

  handleValidationReturn(error: any) {
    this.returnFormValidation = {
      branch: error?.branch,
      name: error?.name,
      email: error?.email,
      phone: error?.phone,
      password: error?.password,
      passwordConfirmation: error?.confirmPassword,
      confirmRegister: '',
    };
  }

  confirRegister(event) {
    this.confirmed = event.target.checked;
  }
}
