import { CommonPaginateResponse, CommonResponse } from './../../../services/api-response.dto';
import { Injectable } from '@angular/core';
import { DailyReportServices } from '../../../services/reports/daily-report.service';

@Injectable({
  providedIn: 'root',
})
export class ReportAdminBloc {
  constructor(private dailyReportServices: DailyReportServices) {}

  public financeSupervisorReportData: any;

  async getDailyReport(param: any): Promise<CommonPaginateResponse> {
    return new Promise(async (resolve) => {
      this.dailyReportServices.getDailyReport(param).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async getMonthlyReport(param: any): Promise<CommonPaginateResponse> {
    return new Promise(async (resolve) => {
      this.dailyReportServices.getMonthlyReport(param).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async getFinanceSupervisorReport(param: any): Promise<CommonPaginateResponse> {
    return new Promise(async (resolve) => {
      this.dailyReportServices.getFinanceSupervisorReport(param).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }

  async getFinanceSupervisorReportNoPagination(param: any): Promise<CommonResponse> {
    return new Promise(async (resolve) => {
      this.dailyReportServices.getFinanceSupervisorReportNoPagination(param).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  }
}
