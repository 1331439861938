import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse, CommonPaginateResponse, TransformApiResponseDto } from '../api-response.dto';
import { ApprovalCustomerDto, CustomerDataDto } from '../../shared/dto/admin/customer.dto';
import { Observable } from 'rxjs';
import { InquiryAddBeneficieryDto, ResponseInquiryAddBeneficieryDto } from 'src/app/shared/dto/customer/beneficieries';
import { toFormData } from '../../helpers/formdata.service';

@Injectable({
  providedIn: 'root',
})
export class DataCustomerService {
  constructor(private http: HttpClient) {}

  public customerData: CustomerDataDto;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  httpOptionsMultipart = {
    headers: new HttpHeaders({
      'Accept': 'multipart/form-data',
    }),
  };

  getData(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/administration/customer', {
      params: param,
    });
  }

  getDataExpiredIc(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/administration/expired-ic', {
      params: param,
    });
  }

  customerNeedApprove(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/administration/customer-need-approval', {
      params: {},
    });
  }

  verifiedCustomer(uuid: string, param: any) {
    return this.http.patch<CommonResponse>(`${environment.apiUrl}/administration/customer/verification/${uuid}`,
      this.httpOptions, {
        params: param,
      }
    );
  }

  addCustomer(param: any, uuid: string) : Observable<CommonResponse> {
    if (uuid) {
      return this.http.post<CommonResponse>(
        `${environment.apiUrl}/administration/customer/${uuid}`,
        toFormData(param)
      );
    } else {
      return this.http.post<CommonResponse>(
        `${environment.apiUrl}/administration/customer`,
        toFormData(param)
      );
    }
  }

  addBeneficieriesByAgent(param: any): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.apiUrl}/administration/customer/beneficiary`,
      param,
      this.httpOptions
    );
  }

  getIdentityImage(uuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/administration/customer/identity/${uuid}`, {
      params: {},
    });
  }

  getIdentitySelfieImage(uuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/administration/customer/selfie/${uuid}`, {
      params: {},
    });
  }

  getIdentitySelfieBackImage(uuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/administration/customer/selfieback/${uuid}`, {
      params: {},
    });
  }

  submitVerificationCustomer(param: any, uuid: string) {
    return this.http.patch<CommonResponse>(`${environment.apiUrl}/administration/customer/verification/${uuid}`,
        param,
        this.httpOptions
      );
  }

  getHasIdentityImage() {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/customer/checkIdentity`, {
      params: {},
    });
  }

  getHasIdentitySelfieImage() {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/customer/checkIdentitySelfie`, {
      params: {},
    });
  }

  getHasIdentitySelfieBackImage() {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/customer/checkIdentitySelfieBack`, {
      params: {},
    });
  }

  getCustomerDetail(uuid: string) {
    return this.http.get<CommonResponse>(`${environment.apiUrl}/administration/customer/${uuid}`, {
      params: {},
    });
  }

  approvalCustomer(uuid: string, param: ApprovalCustomerDto) {
    return this.http.patch<CommonResponse>(`${environment.apiUrl}/administration/customer/approval/${uuid}`,
        param,
        this.httpOptions
      );
  }

  addBeneficieryInquiry(param: InquiryAddBeneficieryDto) {
    return this.http.post<TransformApiResponseDto>(
      `${environment.apiUrl}/remittance/inquery/checkaccount`,
      param,
      this.httpOptions
    );
  }

  deleteCustomer(uuid: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(
      `${environment.apiUrl}/administration/customer/${uuid}`,
      this.httpOptions
    );
  }

  async submitInquiryAddBeneficieryByAgent(
    payload: InquiryAddBeneficieryDto
  ): Promise<TransformApiResponseDto> {
    return new Promise(async (resolve) => {
      this.addBeneficieryInquiry(payload).subscribe(
        async (result) => {
          const returnData = {
            data: result.data,
            message: result.message,
          };
          resolve(returnData);
        },
        (error) => {
          const returnData = {
            data: null,
            message: error.message,
          };
          resolve(returnData);
        }
      );
    });
  }

  
  getBalanceBersamaku(): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(environment.apiUrl + '/bersamaku/balance', {
      params: {},
    });
  }
}
