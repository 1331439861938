import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';

@Injectable({
  providedIn: 'root',
})
export class MasterDataProviderServices {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getMainProvider(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/mainprovider', {
      params: param,
    });
  }

  getCustomerMainProvider(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/customer/mainprovider', {
      params: param,
    });
  }

  getMainProviderSelect(): Observable<Array<Select2OptionData>> {
    const uri = environment.apiUrl + '/mainprovider';
    return new Observable((obs) => {
      this.http.get<CommonResponse>(uri, { params: {} })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose Provider',
            id: '',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.providerName),
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }
}
