import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse, CommonPaginateResponse } from '../api-response.dto';

@Injectable({
  providedIn: 'root',
})
export class ServiceChargeService {
  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }),
  };

  getData(param: any) {
    return this.http.get<CommonPaginateResponse>(environment.apiUrl + '/servicecharge/pagination', {
      params: param,
    });
  }

  getDataNoPagination(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/servicecharge', {
      params: param,
    });
  }

  getCustomerServiceCharge(param: any) {
    return this.http.get<CommonResponse>(environment.apiUrl + '/customer/servicecharge', {
      params: param,
    });
  }

  addServiceCharge(param: any, uuid: string) {
    if (uuid) {
      return this.http.put<CommonResponse>(`${environment.apiUrl}/servicecharge/${uuid}`,
        param,
        this.httpOptions
      );
    } else {
      return this.http.post<CommonResponse>(`${environment.apiUrl}/servicecharge`,
        param,
        this.httpOptions
      );
    }
  }

  deleteServiceCharge(uuid: string) {
    return this.http.delete<CommonResponse>(`${environment.apiUrl}/servicecharge/${uuid}`,
      this.httpOptions
    );
  }
}
