import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationServices } from '../../services/authentication.service';
import { StorageDataService } from '../../helpers/storageData.service';
import { ProfileDataDto } from '../dto/admin/profile.dto';
import { PrintService } from '../../services/remittance/print-receipt.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  route: string;
  analytics: any;
  profile: ProfileDataDto;
  fullName;
  email;

  constructor(
    public sidebarservice: SidebarService,
    public printService: PrintService,
    private authenticationServices: AuthenticationServices,
    private storageDataService: StorageDataService,
    location: Location,
    router: Router,
  ) {
    router.events.subscribe((val) => {
      if (location.path() === '/dashboard') {
        $('html').removeAttr('class');
        this.analytics = true;
      }
    });
  }

  toggleSidebar(): void {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar(): void {
    this.sidebarservice.setSidebarState(true);
  }

  async ngOnInit(): Promise<void> {
    this.profile = await this.storageDataService.getProfileStorage();
    const roleName = this.profile.roles[0].name;
    if (roleName === 'super-admin' || roleName === 'admin') {
      this.fullName = this.profile.email;
      this.email = this.profile.email;
    } else {
      this.fullName = this.profile.agent.name;
      this.email = this.profile.email;
    }

    this.searchSideBar();
  }

  private searchSideBar() {
    /* Search Bar */
    $(document).ready(function () {
      $('.mobile-search-icon').on('click', function () {
        $('.search-bar').addClass('full-search-bar');
      }),
        $('.search-close').on('click', function () {
          $('.search-bar').removeClass('full-search-bar');
        });
    });
  }

  logout(): void {
    this.authenticationServices.logout(false);
  }
}
