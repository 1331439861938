<header id="topnav" class="defaultscroll sticky box-shadow-user" [ngClass]="{'bg-white' : notHome}">
  <div class="container">
    <a class="logo" routerLink="/">
      <span class="logo-light-mode">
        <img src="assets/images/new-logo/prima-express-dark.png" class="l-dark" height="84" alt="">
        <img src="assets/images/new-logo/prima-express-dark.png" class="l-light" height="84" alt="">
      </span>
      <img src="assets/images/new-logo/prima-express-dark.png" height="84" class="logo-dark-mode" alt="">
    </a>

    <div class="menu-extras">
      <div class="menu-item">
        <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>

    <ul class="buy-button list-inline mb-0" *ngIf="!logged">
      <li class="list-inline-item mb-0">
        <a (click)="routeHelper.navigated('register')" class="btn btn-primary"><i class='bx bx-group'></i>
        </a>
      </li>

      <li class="list-inline-item ps-1 mb-0">
        <a (click)="routeHelper.navigated('login')" class="btn btn-info"><i class='fa fa-sign-in'></i>
        </a>
      </li>
    </ul>

    <ul class="buy-button list-inline mb-0" *ngIf="logged">
      <li class="list-inline-item mb-0">
        <a (click)="goToPage()" class="btn btn-primary"><i class='bx bx-group'></i>
        </a>
      </li>
    </ul>

    <div id="navigation">
      <ul class="navigation-menu nav-light">
        <li><a (click)="routeHelper.navigated('/')" class="">Home</a></li>
        <li><a (click)="routeHelper.navigated('how-to-verify-identity')" class="">How To Verify Identity</a></li>
        <li><a (click)="routeHelper.navigated('services')" class="">Services</a></li>
        <li><a (click)="routeHelper.navigated('our-agents')" class="">Our Agents</a></li>
      </ul>
    </div>
  </div>
</header>

<router-outlet></router-outlet>

<div class="position-relative">
  <div class="shape overflow-hidden shape-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<footer class="footer">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="footer-py-60 text-center">
          <div class="row py-5">
            <div class="col-md-4">
              <div class="card border-0 text-center features feature-clean bg-transparent"
                style="box-shadow: none !important;">
                <div class="icons text-white text-center mx-auto">
                  <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                </div>
                <div class="content mt-3">
                  <h5 class="footer-head">Phone</h5>
                  <p class="text-muted">Start working with Landrick that can provide everything</p>
                  <a href="tel:+152534-468-854" class="text-foot">+152 534-468-854</a>
                </div>
              </div>
            </div>

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="card border-0 text-center features feature-clean bg-transparent"
                style="box-shadow: none !important;">
                <div class="icons text-white text-center mx-auto">
                  <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                </div>
                <div class="content mt-3">
                  <h5 class="footer-head">Email</h5>
                  <p class="text-muted">Start working with Landrick that can provide everything</p>
                  <a href="mailto:contact@example.com" class="text-foot">contact@example.com</a>
                </div>
              </div>
            </div>

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="card border-0 text-center features feature-clean bg-transparent"
                style="box-shadow: none !important;">
                <div class="icons text-white text-center mx-auto">
                  <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                </div>
                <div class="content mt-3">
                  <h5 class="footer-head">Location</h5>
                  <p class="text-muted">C/54 Northwest Freeway, Suite 558, <br>Houston, USA 485</p>
                  <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    data-type="iframe" class="video-play-icon text-foot lightbox">View on Google map</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer-py-30 footer-border">
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="uil uil-truck align-middle h5 mb-0 me-2"></i>
                  Free delivery
                </div>
              </div>

              <div class="col-lg-3">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="uil uil-archive align-middle h5 mb-0 me-2"></i>
                  Non-contact shipping
                </div>
              </div>

              <div class="col-lg-3">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="uil uil-transaction align-middle h5 mb-0 me-2"></i>
                  Money-back quarantee
                </div>
              </div>

              <div class="col-lg-3">
                <div class="d-flex align-items-center justify-content-center">
                  <i class="uil uil-shield-check align-middle h5 mb-0 me-2"></i>
                  Secure payments
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-py-30 footer-bar bg-light">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-9 col-md-9 col-sm-9 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <ul class="list-unstyled footer-list terms-service mb-0">
            <li class="list-inline-item mb-0"><a href="javascript:void(0)" (click)="routeHelper.navigated('privacy-policy')" class="text-footer me-2">Privacy</a></li>
            <li class="list-inline-item mb-0"><a href="javascript:void(0)" (click)="routeHelper.navigated('term-of-use')" class="text-footer me-2">Terms</a></li>
            <li class="list-inline-item mb-0"><a href="javascript:void(0)" class="text-footer me-2">FAQs</a></li>
            <li class="list-inline-item mb-0"><a href="javascript:void(0)" class="text-footer">Contact</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="text-sm-end">
            <p class="mb-0 text-footer">&copy; {{ dateNow }}. Prima Express</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
