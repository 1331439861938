import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { RegisterAgent } from '../../../shared/dto/auth.dto';
import { AuthenticationServices } from '../../../services/authentication.service';
import { Select2OptionData } from 'ng-select2';
import { Observable, Subject } from 'rxjs';
import { RegionDataServices } from '../../../services/masterdata/regionData.service';
import { RateManagementServices } from '../../../services/administation/rate-management.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-register-agent',
  templateUrl: './register-agent.component.html',
  styleUrls: ['./register-agent.component.css'],
})
export class RegisterAgentComponent implements OnInit {
  constructor(
    private router: Router,
    private authenticationServices: AuthenticationServices,
    private regionDataService: RegionDataServices,
    private rateManagementServices: RateManagementServices
  ) {}

  registerAgentForm = new FormGroup({
    accountEmail: new FormControl('', Validators.required),
    accountName: new FormControl('', Validators.required),
    accountPhone: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    passwordConfirmation: new FormControl('', Validators.required),

    bussinessName: new FormControl('', Validators.required),
    bussinessPhone: new FormControl('', Validators.required),
    basedCurrency: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    postalCode: new FormControl(''),
    confirmRegister: new FormControl('', Validators.required),
    recaptcha: new FormControl('', Validators.required)
  });

  errRegister: boolean;
  errResponse: boolean;
  messageResponse: string;
  preloader: boolean;
  returnFormValidation: RegisterAgent;
  thanksPage: boolean;

  countriesData = [];
  provinceData = [];
  cityData = [];

  currenciesData = [];

  disableProvince: boolean;
  disableCity: boolean;
  siteKey :string
  size :any
  theme :any
  type :any

  async ngOnInit() {
    this.size = "normal";
    this.theme = "light";
    this.type = "image"
    this.siteKey = "6LcNTUcgAAAAAMP7brHhKd9zx72oV4iNYJ98flGD";
    this.errRegister = false;
    this.preloader = false;
    this.thanksPage = false;

    this.disableProvince = true;
    this.disableCity = true;

    window.scrollTo(0, 0);

    await this.getCountries();
    await this.getCurrencies();
  }

  navigated(str: string) {
    this.router.navigate([str]);
  }

  async getCurrencies() {
    await this.rateManagementServices
      .getBaseCurrenciesSelect(null)
      .pipe(first())
      .subscribe(
        (data) => {
          for (const iterator of data.data) {
            this.currenciesData.push({
              optionId: iterator.code,
              optionTitle: `${iterator.code} - ${iterator.name}`,
            });
          }
        },
        (error) => {
          this.currenciesData = [];
        }
      );
  }

  async getCountries() {
    await this.regionDataService
      .getCountriesSelectOption(null)
      .pipe(first())
      .subscribe(
        (data) => {
          for (const iterator of data.data) {
            this.countriesData.push({
              optionId: iterator.uuid,
              optionTitle: iterator.name,
            });
          }
        },
        (error) => {
          this.countriesData = [];
        }
      );
  }

  async getProvinces(param: any) {
    await this.regionDataService
      .getProvincesSelectOption(param)
      .pipe(first())
      .subscribe(
        (data) => {
          this.provinceData = [];
          if (data.data.length > 0) {
            for (const iterator of data.data) {
              this.provinceData.push({
                optionId: iterator.uuid,
                optionTitle: iterator.name,
              });
            }
          }
        },
        (error) => {
          this.provinceData = [];
        }
      );
  }

  async getCities(param: any) {
    await this.regionDataService
      .getCitiesSelectOption(param)
      .pipe(first())
      .subscribe(
        (data) => {
          this.cityData = [];
          if (data.data.length > 0) {
            for (const iterator of data.data) {
              this.cityData.push({
                optionId: iterator.uuid,
                optionTitle: iterator.name,
              });
            }
          }
        },
        (error) => {
          this.cityData = [];
        }
      );
  }

  async onSubmit() {
    const dataRegister: RegisterAgent = this.registerAgentForm.value;
    dataRegister.accountName = dataRegister.accountName.replace(/\s/g, "");

    if (this.registerAgentForm.valid && dataRegister.confirmRegister) {
      this.preloader = true;
      let responseData = null;

      responseData = await this.registerPost(dataRegister);
      if (responseData) {
        this.preloader = false;
        this.thanksPage = true;
        this.router.events.subscribe((x) => {
          if (x instanceof NavigationEnd) {
            window.scrollTo(0, 0);
          }
        });
      }
    }else{
      if(dataRegister.recaptcha === ''){
        this.preloader = false;
        this.errRegister = true;
        this.messageResponse = 'Captcha Invalid';
        this.handleValidationReturn('Captcha Invalid');
      }else{
        this.preloader = false;
        this.errRegister = true;
        this.messageResponse = 'Something when wrong when register your data';
        this.handleValidationReturn('Something when wrong when register your data');
      }
    }
  }

  async registerPost(registerAgent: RegisterAgent) {
    return new Promise(async (resolve) => {
      this.authenticationServices.registerAgent(registerAgent).subscribe(
        (result) => {
          resolve(result);
          this.preloader = false;
        },
        (error) => {
          this.preloader = false;
          this.messageResponse = error.message;
          this.errRegister = true;
          this.handleValidationReturn(error.errors);
        }
      );
    });
  }

  get validating() {
    return this.registerAgentForm.controls;
  }

  handleValidationReturn(error: any) {
    this.returnFormValidation = {
      accountEmail: error?.accountEmail,
      password: error?.password,
      passwordConfirmation: error?.passwordConfirmation,
      accountName: error?.accountName,
      accountPhone: error?.accountPhone,
      bussinessName: error?.bussinessName,
      bussinessPhone: error?.bussinessPhone,
      country: error?.country,
      province: error?.province,
      city: error?.city,
      address: error?.address,
      postalCode: error?.postalCode,
      confirmRegister: '',
      basedCurrency: error?.basedCurrency,
      recaptcha:''
    };
  }

  async changeCountry(event) {
    const value = event[0].value;
    if (String(value) !== '0' && String(value) !== '') {
      const param = {
        countryUuid: value,
      };
      this.provinceData = [];
      await this.getProvinces(param);
    }
  }

  async changeProvince(event) {
    const value = event[0].value;
    if (String(value) !== '0' && String(value) !== '') {
      const param = {
        provinceUuid: value,
      };
      this.cityData = [];
      await this.getCities(param);
    }
  }
}
