import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';

@Injectable({
  providedIn: 'root',
})
export class RegionDataServices {
  constructor(private http: HttpClient) {}

  getCountriesSelectOption(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/countries', {
      params: param,
    });
  }

  getProvincesSelectOption(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/provinces', {
      params: param,
    });
  }

  getCitiesSelectOption(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/cities', {
      params: param,
    });
  }

  getRegenciesSelectOption(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/regencies', {
      params: param,
    });
  }

  getCountriesSelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/countries', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose country',
            id: '0',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.name),
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }

  getCountriesNameSelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/countries', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose country',
            id: '0',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.name),
              id: String(e.name),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }

  getProvincesSelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/provinces', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose Province',
            id: '0',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.name),
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }

  getCitySelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/cities', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose City',
            id: '0',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.name),
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }

  getRegenciesSelect(param: any): Observable<Array<Select2OptionData>> {
    return new Observable((obs) => {
      this.http.get<CommonResponse>(environment.apiUrl + '/regencies', { params: param })
      .subscribe(
        (data) => {
          const responseData = data.data;
          const displayData: Array<Select2OptionData> = [];

          displayData.push({
            text: 'Choose Regencies',
            id: '0',
          });
          responseData.map((e) => {
            displayData.push({
              text: String(e.name),
              id: String(e.uuid),
            });
          });
          obs.next(displayData);
          obs.complete();
        }
      );
    });
  }
}
