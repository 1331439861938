import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonResponse } from '../api-response.dto';
import { Observable } from 'rxjs';
import { toFormData } from '../../helpers/formdata.service';
import { UpdateProfileDto } from '../../shared/dto/customer/profile.dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileServices {
  constructor(private http: HttpClient) {}

  getProfile(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/customer/me', { params: param });
  }

  getEkyc(param: any): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(environment.apiUrl + '/customer/ekyc/check', { params: param });
  }

  submitProfile(param: UpdateProfileDto): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(environment.apiUrl + '/customer/ekyc', toFormData(param));
  }
}
