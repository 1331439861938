import { LoginAgentComponent } from './mainpage-site/pages/login-agent/login-agent.component';
import { MainPageLayoutComponent } from './layouts/mainpage/mainpage-layout.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AvatarModule, AvatarSource } from 'ngx-avatar';
import { CookieService } from 'ngx-cookie-service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './mainpage-site/pages/login/login.component';
import { RegisterComponent } from './mainpage-site/pages/register/register.component';
import { RegisterAgentComponent } from './mainpage-site/pages/register-agent/register-agent.component';
import { ForgotPasswordComponent } from './mainpage-site/pages/forgot-password/forgot-password.component';
import { ConfirmDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogService } from './shared/confirmation-dialog/confirmation-dialog.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxSelectModule } from 'ngx-select-ex';
import { MemberLayoutComponent } from './layouts/member/member-layout.component';
import { HomeComponent } from './mainpage-site/pages/home/home.component';
import { ResetPasswordComponent } from './mainpage-site/pages/reset-password/reset-password.component';
import { ReceiptComponent } from './admin-site/receipt/receipt.component';
import { PrintFinanceSupervisorReportComponent } from './admin-site/print-finance-supervisor-report/print-finance-supervisor-report.component';
import { NgxCaptchaModule } from 'ngx-captcha';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const avatarSourcesOrder = [AvatarSource.CUSTOM, AvatarSource.INITIALS];
const avatarColors = ['#FFB6C1', '#2c3e50', '#95a5a6', '#f39c12', '#1abc9c'];

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    MemberLayoutComponent,
    MainPageLayoutComponent,
    LoginComponent,
    LoginAgentComponent,
    RegisterComponent,
    RegisterAgentComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmDialogComponent,
    HomeComponent,
    ReceiptComponent,
    PrintFinanceSupervisorReportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    PerfectScrollbarModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxSelectModule,
    NgxPaginationModule,
    CurrencyMaskModule,
    AvatarModule.forRoot({
      sourcePriorityOrder: avatarSourcesOrder,
      colors: avatarColors,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4',
    }),
    NgxDaterangepickerMd.forRoot(),
    NgxCaptchaModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    CookieService,
    ConfirmDialogService,
  ],
  bootstrap: [AppComponent],
  exports: [ConfirmDialogComponent],
})
export class AppModule {}
